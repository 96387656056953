<template>
    <div>
        <div class="flex items-center mb-4">
            <div class="w-1/2 text-left">Start time</div>
            <div>
                <div class="inline-block relative w-48">
                    <select  v-model="in_time" class="block appearance-none w-full border-2 px-4 py-2 pr-8 rounded-lg leading-tight">
                        <option v-for="(item, index) in in_time_range" :value="item" :key="index">{{ item }}</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center mb-4">
            <div class="w-1/2 text-left">End time</div>
            <div>
                <div class="inline-block relative w-48">
                    <select v-model="out_time" class="block appearance-none w-full border-2 px-4 py-2 pr-8 rounded-lg leading-tight" :class="(errors.errors && errors.errors.sick_day_hours) ? 'border border-red-800':''">
                        <option v-for="(item, index) in out_time_range" :value="item" :key="index">{{ item }}</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
                <div v-if="errors.errors && errors.errors.sick_day_hours" class="w-48">
                    <div class="w-full text-sm text-red-800 text-left pt-2" v-text="errors.errors.sick_day_hours[0]"></div>
                </div>
            </div>
        </div>

        <!-- when temp for client worked by regular -->
        <div class="flex items-center mb-2" v-if="!breakdownShift && !this.client.is_client_internal" :class=" !createWorkdayAllow ? 'border border-red-800 p-2 rounded-lg' : ''">
            <div class="w-1/2 text-left">Break time</div>
            <div>
                <div class="inline-block relative w-48">
                    <select v-model="break_time_shifts.shift_1" class="block appearance-none w-full bg-white border-2 px-3 py-2 pr-6 rounded-lg leading-tight">
                        <option v-for="(item, index) in break_time_range" :value="item.key" :key="index">{{ item.value }}</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
            </div>
        </div>

        <!-- when temp for client worked by shifts -->
        <div v-if="breakdownShift && !this.client.is_client_internal">
            <div class="flex items-center p-2 rounded-tl rounded-tr cursor-pointer relative modal-tooltip" :class=" !createWorkdayAllow ? 'border border-red-800 p-2' : ''">
                <div class="text-left">Break time</div>
                <div class="tooltip inline" v-if="dayFormat.indexOf('Sat') != -1 || dayFormat.indexOf('Sun') != -1">
                    <!-- <svg id="Capa_1" class="w-4 h-4 ml-1 mt-1" enable-background="new 0 0 524.235 524.235" height="512" viewBox="0 0 524.235 524.235" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m262.118 0c-144.53 0-262.118 117.588-262.118 262.118s117.588 262.118 262.118 262.118 262.118-117.588 262.118-262.118-117.589-262.118-262.118-262.118zm17.05 417.639c-12.453 2.076-37.232 7.261-49.815 8.303-10.651.882-20.702-5.215-26.829-13.967-6.143-8.751-7.615-19.95-3.968-29.997l49.547-136.242h-51.515c-.044-28.389 21.25-49.263 48.485-57.274 12.997-3.824 37.212-9.057 49.809-8.255 7.547.48 20.702 5.215 26.829 13.967 6.143 8.751 7.615 19.95 3.968 29.997l-49.547 136.242h51.499c.01 28.356-20.49 52.564-48.463 57.226zm15.714-253.815c-18.096 0-32.765-14.671-32.765-32.765 0-18.096 14.669-32.765 32.765-32.765s32.765 14.669 32.765 32.765c0 18.095-14.668 32.765-32.765 32.765z"/>
                    </svg>
                    <span class='tooltip-text ml-0 left-0 rounded'>All hours and breaks on Sat and Sun are weekend/3rd shift</span> -->
                </div>
            </div>

            <div class="mb-2 p-2 rounded-bl rounded-br" :class=" !createWorkdayAllow ? 'border-l border-r border-b border-red-800 p-2' : ''">
                <div class="flex justify-between">
                    <div>
                        <div class="text-center text-sm">1st shift</div>
                        <div class="inline-block relative w-22">
                            <select v-model="break_time_shifts.shift_1" :disabled="checkBreakTime(1)" class="block appearance-none w-full border-2 px-3 py-2 pr-6 rounded-lg leading-tight">
                                <option v-for="(item, index) in break_time_range" :value="item.key" :key="index">{{ item.value }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="text-center text-sm">2nd shift</div>
                        <div class="inline-block relative w-22">
                            <select v-model="break_time_shifts.shift_2" :disabled="checkBreakTime(2)" class="block appearance-none w-full border-2 px-3 py-2 pr-6 rounded-lg leading-tight">
                                <option v-for="(item, index) in break_time_range" :value="item.key" :key="index">{{ item.value }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="text-center text-sm">3rd shift</div>
                        <div class="inline-block relative w-22">
                            <select v-model="break_time_shifts.shift_3" :disabled="checkBreakTime(3)" class="block appearance-none w-full border-2 px-3 py-2 pr-6 rounded-lg leading-tight">
                                <option v-for="(item, index) in break_time_range" :value="item.key" :key="index">{{ item.value }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!createWorkdayAllow" class="mb-4 grid justify-items-end">
            <span class="text-sm text-red-800">Your break time is greater than your work time</span>
        </div>

        <div class="flex items-center font-medium">
            <div class="w-1/2 text-left">Total</div>
            <div>
                <div class="w-48 text-right">{{ total_hour}} hrs</div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['inTime', 'outTime', 'breakTimeShifts', 'client', 'dayFormat', 'breakdownShift', 'errors'],
        computed: {
            in_time: {
                get() {
                    return this.inTime
                },
                set(value) {
                    this.$emit('update:inTime', value)
                }
            },

            out_time: {
                get() {
                    return this.outTime
                },
                set(value) {
                    this.$emit('update:outTime', value)
                }
            },

            break_time_shifts: {
                get() {
                    return this.breakTimeShifts
                },
                set(value) {
                    this.$emit('update:breakTimeShifts', value)
                }
            },
        },


        data () {
            return {
                in_time_range: window.getTimeRange(),
                out_time_range: window.getTimeRange(),
                break_time_range: window.getBreakTimeRange(),
                access_shifts: [1],
                total_hour: '',
                createWorkdayAllow: true
            }
        },

        created() {
            this.$nextTick(()=> {
                this.out_time_range = window.getOutTimeRange(this.in_time);
                if (this.out_time.length === 0 && this.in_time.length > 0 )
                {
                    this.out_time = window.addTimeToString(this.in_time, 8);
                }

                this.total_hour = window.getTotalHour(this.in_time, this.out_time, this.break_time_shifts);
            });

            if(this.client)
            {
                this.access_shifts = this.getAccessShifts(this.in_time, this.out_time, this.client);
            }
        },

        methods: {
            checkBreakTime(shift) {
                let active =  this.access_shifts.indexOf(shift) < 0;
                if (active) {
                    switch(shift) {
                        case 1:
                            this.break_time_shifts.shift_1 = '00:00';
                            break;
                        case 2:
                            this.break_time_shifts.shift_2 = '00:00';
                            break;
                        case 3:
                            this.break_time_shifts.shift_3 = '00:00';
                            break;
                    }
                }

                return active;
            },

            getTimesByShift(schedule_shifts) {
                let times_by_shift = [];
                schedule_shifts.map((item, i) => {
                    let in_time = item[0];
                    let out_time = item[1];

                    let start = moment('2020-04-20 ' + in_time, 'YYYY-MM-DD h:mm A');
                    let end = moment('2020-04-20 ' + out_time, 'YYYY-MM-DD h:mm A');

                    if (end.diff(start) < -1) {
                        end.add(1, 'day');
                    }

                    let items = [];
                    for (let m = moment(start); m.isBefore(end); m.add(15, 'minutes')) {
                        items.push(m.format('h:mm A'));
                    }
                    items.push(end.format('h:mm A'));

                    times_by_shift[i] = items;
                });

                return times_by_shift;
            },

            getAccessShifts(in_time, out_time, client) {
                let special_time = '7:00 AM';
                let regular_time = '9:30 AM';
                let schedule_shifts = [["9:30 AM","5:30 PM"],["5:30 PM","12:30 AM"],["12:30 AM","9:30 AM"]];
                let diff_start_and_special_time = moment(special_time, 'h:mm A').diff(moment(in_time, 'h:mm A'));
                let diff_start_and_schedule_time = moment(regular_time, 'h:mm A').diff(moment(in_time, 'h:mm A'));

                if (client.timesheet_template !== 'shift' && diff_start_and_special_time <= 0 && diff_start_and_schedule_time > 0) {
                    schedule_shifts[0][0] = special_time;
                    schedule_shifts[2][1] = special_time;
                }
                
                if(this.$parent.$parent.day_format.indexOf('Sat') !== -1 || this.$parent.$parent.day_format.indexOf('Sun') !== -1)
                {
                    return [3];
                }

                let times_by_shift = this.getTimesByShift(schedule_shifts);
                let start_time = moment('2020-04-20 ' + in_time, 'YYYY-MM-DD h:mm A');
                let end_time = moment('2020-04-20 ' + out_time, 'YYYY-MM-DD h:mm A');

                if (end_time.diff(start_time) < -1) {
                    end_time.add(1, 'day');
                }

                let items = [];
                for (let m = moment(start_time); m.isBefore(end_time); m.add(15, 'minutes')) {
                    items.push(m.format('h:mm A'));
                }
                items.push(end_time.format('h:mm A'));

                let access_shifts = [];
                times_by_shift.map((times, i) => {
                    let access_times = [];
                    items.map(time => {
                        if (times.indexOf(time) > -1) {
                            access_times.push(time);
                        }
                    })
                    if (access_times.length > 1) {
                        access_shifts.push(i + 1);
                    }
                });

                return access_shifts;
            }
        },

        watch: {
            in_time(val, oldVal)
            {
                this.out_time_range = window.getOutTimeRange(val);

                this.$nextTick(()=> {
                    if (val !== oldVal && oldVal.length > 0) {
                        this.out_time = window.addTimeToString(val, 8);
                    }
                });

                this.total_hour = window.getTotalHour(val, this.out_time, this.break_time_shifts);
                this.access_shifts = this.getAccessShifts(val, this.out_time, this.client);

                this.$emit('get_total_hour', this.total_hour);

                // If total hour less than 0 restrict user to create workday.
                this.createWorkdayAllow = true
                if (this.total_hour < 0) {
                    this.createWorkdayAllow = false
                }
            },

            out_time(val)
            {
                this.total_hour = window.getTotalHour(this.in_time, val, this.break_time_shifts);
                this.access_shifts = this.getAccessShifts(this.in_time, val, this.client);

                this.$emit('get_total_hour', this.total_hour);

                // If total hour less than 0 restrict user to create workday.
                this.createWorkdayAllow = true
                if (this.total_hour < 0) {
                    this.createWorkdayAllow = false
                }
            },


            break_time_shifts: {
                handler: function (val) {
                    this.total_hour = window.getTotalHour(this.in_time, this.out_time, val);
                    this.$emit('get_total_hour', this.total_hour);

                    // If total hour less than 0 restrict user to create workday.
                    this.createWorkdayAllow = true
                    if (this.total_hour < 0) {
                        this.createWorkdayAllow = false
                    }
                },
                deep: true
            },
        },
    }
</script>
<style>
    select[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
    }
</style>