<template>
    <modal name="workday-confirm-modal" :width="350" :height="'auto'" @before-open="beforeOpen">
        <div class="mt-6 mb-6 px-4 relative">
            <div class="text-center">
                <popup-header :title="'Workday'"
                              :sub-title="''"
                              :model-id="'workday-confirm-modal'"></popup-header>

                <div class="my-4 text-red text-xl bg-orange-400est border-orange-600 border p-2 text-orange-600">
                    {{ this.dateFormat(this.day,'ddd M/DD') }}, is {{this.dateFormatNow(this.day)}} in the future
                </div>

                <div class="flex mb-4 justify-center">
                    <label class="block" for="workday_popup">
                        <input class="mr-2 leading-tight" type="checkbox" v-model="workday_popup" id="workday_popup">
                        <span class="text-sm">Don't show this again</span>
                    </label>
                </div>

                <div>
                    <div class="flex justify-between">
                        <button v-if="!loading" @click="cancelPopup" class="bg-gray-600 text-white py-2 px-4 rounded-full border shadow w-32">
                            Cancel
                        </button>
                        <button v-if="!loading" @click="submitPopup" class="bg-green-600 text-white py-2 px-4 rounded-full border shadow w-32">
                            OK
                        </button>
                    </div>

                    <loading :width="'24px'" :height="'30px'" :show="loading"></loading>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import moment from 'moment';
    import PopupHeader from "./PopupHeader";

    export default {
        name: 'PopupWorkdayConfirm',
        components: {PopupHeader},
        data () {
            return {
                day: '',
                client: null,
                temp: null,

                in_time:  '9:30 AM',
                out_time: '',

                break_time_shifts: {
                    shift_1: '00:00',
                    shift_2: '00:00',
                    shift_3: '00:00'
                },

                comment: '',

                workday_popup: false,
                loading: false
            }
        },

        watch: {
            workday_popup(val) {
                if(val)
                    localStorage.setItem("workday_popup", val);
                else
                    localStorage.removeItem("workday_popup");
            },
        },

        methods: {
            beforeOpen (event)
            {
                this.day = event.params.day;
                this.temp = event.params.temp;
                this.client = event.params.client;
                this.in_time = event.params.in_time;
                this.out_time = event.params.out_time;
                this.break_time_shifts = event.params.break_time_shifts;
                this.comment = event.params.comment;
            },

            cancelPopup()
            {
                this.$modal.hide('workday-confirm-modal');
                this.$modal.hide('create-workday-modal');
            },

            submitPopup()
            {
                this.$modal.hide('workday-confirm-modal');
                this.$emit('create-workday');
            },

            dateFormat(datetime, format) {
                return moment(datetime).tz('America/New_York', true).format(format);
            },

            dateFormatNow(datetime) {
                return moment(datetime).tz('America/New_York', true).fromNow();
            },
        }
    }
</script>