<template>
    <modal name="signup-modal" :height="'auto'" :scrollable="true" :adaptive="true" @before-open="beforeOpen">
        <div v-if="!success">
            <div class="px-12 text-center h-16 flex items-center justify-center border-b header" :class="errors.get('email') ? 'error' : ''">
                <div class="flex flex-col">
                    <span class="font-bold text-lg">Request a Meeting With an Expert</span>
                    <div class="text-sm" v-text="errors.get('email')"></div>
                </div>

                <button class="absolute rounded-full p-1 hover:bg-gray-300 focus:outline-none" @click="$modal.hide('signup-modal')" style="right: 14px; top: 16px;">
                    <svg aria-hidden="true" role="presentation" focusable="false" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;"><path d="m6 6 20 20"></path><path d="m26 6-20 20"></path></svg>
                </button>
            </div>    

            <div class="bg-white rounded-lg overflow-hidden shadow-2xl" >
                <div class="p-8">
                    <form method="POST" class="" action="#" onsubmit="return false;">
                        <!--<div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-600">Name</label>

                            <div class="text-box rounded">
                                <input type="text" v-model='name' class="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none">
                            </div>
                            <div class="text-sm text-red-500" v-if="errors.get('name')">{{ errors.get('name') }}</div>
                        </div>-->

                        <div class="mb-5">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-600">Email</label>

                            <div class="text-box rounded">
                                <input type="text" v-model='email' class="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none">
                                <div class="text-sm text-red-500" v-if="errors.get('email')">{{ errors.get('email') }}</div>
                            </div>
                        </div>

                        <button @click="submit" class="w-full h-12 mt-4 bg-gray-600 text-white hover:bg-green-700 rounded shadow flex justify-center items-center">
                            <svg v-if="loading" version="1.1" class="w-10 h-10" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform 
                                        attributeName="transform" 
                                        attributeType="XML" 
                                        type="rotate"
                                        dur="1s" 
                                        from="0 50 50"
                                        to="360 50 50" 
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                            <span v-else>Sign up</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div v-if="success">
                <div class="bg-white overflow-hidden shadow-2xl flex flex-col justify-center">
                    <div class="h-32 bg-green-600 w-full text-white flex justify-center items-center">
                        <svg class="h-12 w-12 fill-current" height="512pt" viewBox="0 -21 512.016 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m234.667969 469.339844c-129.386719 0-234.667969-105.277344-234.667969-234.664063s105.28125-234.6679685 234.667969-234.6679685c44.992187 0 88.765625 12.8203125 126.589843 37.0976565 7.425782 4.78125 9.601563 14.679687 4.820313 22.125-4.796875 7.445312-14.675781 9.597656-22.121094 4.820312-32.640625-20.972656-70.441406-32.042969-109.289062-32.042969-111.746094 0-202.667969 90.921876-202.667969 202.667969 0 111.742188 90.921875 202.664063 202.667969 202.664063 111.742187 0 202.664062-90.921875 202.664062-202.664063 0-6.679687-.320312-13.292969-.9375-19.796875-.851562-8.8125 5.589844-16.621094 14.378907-17.472656 8.832031-.8125 16.617187 5.589844 17.472656 14.378906.722656 7.53125 1.085937 15.167969 1.085937 22.890625 0 129.386719-105.277343 234.664063-234.664062 234.664063zm0 0"/><path d="m261.332031 288.007812c-4.09375 0-8.191406-1.558593-11.304687-4.691406l-96-96c-6.25-6.253906-6.25-16.386718 0-22.636718s16.382812-6.25 22.632812 0l84.695313 84.695312 223.335937-223.339844c6.253906-6.25 16.386719-6.25 22.636719 0s6.25 16.382813 0 22.632813l-234.667969 234.667969c-3.136718 3.113281-7.230468 4.671874-11.328125 4.671874zm0 0"/></svg>
                    </div>
                    <button class="absolute rounded-full text-white p-1 hover:bg-green-300 focus:outline-none" @click="$modal.hide('signup-modal')" style="right: 14px; top: 16px;">
                        <svg aria-hidden="true" role="presentation" focusable="false" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;"><path d="m6 6 20 20"></path><path d="m26 6-20 20"></path></svg>
                    </button>
                    <div class="py-8 px-4 text-center">
                        <div class="text-2xl font-medium">Your account is ready</div>
                        <div class="text-gray-600">Please check your email for activation</div>
                    </div>

                    <!--<div class="flex justify-center mb-4 text-center">
                        <a :href="urlLogin" class="p-3 bg-gray-600 text-white rounded shadow hover:bg-green-700 w-24">Login</a>
                    </div>-->

                </div>
            </div>
        </transition>
        
    </modal>
</template>

<script>

class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }


    record(errors) {
        this.errors = errors;
    }
}

export default {
    data() {
        return {
            email: '',
            name: '',
            loading: false,
            success: false,

            errors: new Errors,
        }
    },


    methods: {
        beforeOpen() {
            this.email = '';
            this.name = '';
            this.loading = false;
            this.errors = new Errors;
            this.success = false;
        },

        submit() {
            this.errors = new Errors();
            this.loading = true;
            //this.success = true;

            window.axios.post(process.env.VUE_APP_SIGNUP_URL, { email: this.email })
                .then(() => {
                    this.loading = false;
                    this.success = true;
                })
                .catch(error => {
                    this.loading = false;
                    this.errors.record(error.response.data.errors)
                });
        }
    }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    }
</style>