<template>
    <div id="approve-timesheet" class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
        <div class="flex flex-col md:flex-row items-center -mx-3 gap-10">
            <div class="order-1 w-full px-3 lg:w-1/2 lg:order-1">

                <div class="bg-white rounded-lg p-6 border border-gray-100 shadow-2xl text-center">
                    <div class="text-center mt-2 py-2 leading-normal text-lg">
                        <div class="flex justify-center items-center">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-current text-black w-8 h-8">
                                    <title>Checkmark Circle</title>
                                    <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path>
                                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"></path>
                                </svg>
                            </span>
                            <div class="font-normal text-xl ml-3">
                                Pending Timesheet
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col text-center mt-4 ml-3">
                        <span class="text-gray-800 font-medium text-4xl">{{ approveHours }} hrs</span>
                    </div>
                    <div class="text-center mt-2 py-2 leading-normal text-lg">John Smith <span class="ml-1">2/19</span></div>
                    <div class="text-center mt-1 py-2 leading-normal text-sm text-gray-800">
                        A PDF copy of the pending timesheet has been emailed to you.
                    </div>
                    <div class="mt-6">
                        <a href="#payroll" @click="approve()" class="block w-full px-10 py-4 text-base font-bold leading-6 text-white text-center bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">Approve It</a>
                        <button @click="$parent.sendMeTimesheet()" class="mt-5 block w-full px-10 py-4 text-base font-bold leading-6 text-white text-center bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">Send Me A Timesheet To Approve</button>
                    </div>
                </div>

            </div>
            <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-0 lg:mb-0">
                <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                    Approve Timesheet
                </h2>
                <p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
                    Timesheet approval so simple your clients will love it
                </p>
                <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>No Sign Up For Approval</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>1-Click Approval Process</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Radical Transparency</h3>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: ['approveHours'],

    methods: {
        approve() {
            window.App.event(window.App.analytics, 'experience_approve_click', { status: 'true' });
            this.$emit('approveTimesheet');
        }
    }

}
</script>