import Vue from 'vue'
import App from './App.vue'

import VueTypedJs from 'vue-typed-js';
Vue.use(VueTypedJs);

import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

import VModal from 'vue-js-modal';
Vue.use(VModal, { componentName: "modal" });

import moment from 'moment-timezone';
Vue.prototype.moment = moment;
window.moment = moment;

import AOS from 'aos';
import 'aos/dist/aos.css';

window.axios = require('axios');

Vue.config.productionTip = false

require('./helpers/datetime');

import '@/assets/calendly.css';

//Vue Filters
const dateFormat = function (str) {
  if (str != null) {
      return moment(str).format("MM/DD/YYYY");
  }
  return "";
};

const filters = {
  dateFormat,
};

//register the filters in Vue
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));
Vue.component('loading', require('./components/Loading.vue').default);


new Vue({
  created() {
    AOS.init()
  },
  data() {
    return {
      login_url: process.env.VUE_APP_LOGIN_URL,
    }
  },
  methods: {
    dateFormat(datetime, format) {
        return moment(datetime).tz('America/New_York', true).format(format);
    },
  },
  render: h => h(App),
}).$mount('#app')
