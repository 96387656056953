<template>
    <div id="approve-timesheet-inner" class="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 md:mb-20 md:flex-row max-w-7xl lg:px-16">
        <div class="box-border w-full text-black border-solid md:w-1/2 md:pl-6">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                Approve Timesheet
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
                Spend less time on paperwork, and more on growing your business.
            </p>
            <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                    <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-black bg-blue-cobalt rounded-full" data-primary="indigo-400"><span class="text-sm font-bold text-white">✓</span></span>
                    Faster processing of timesheets
                </li>
                <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                    <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-black bg-blue-cobalt rounded-full" data-primary="indigo-400"><span class="text-sm font-bold text-white">✓</span></span>
                    Centralized record-keeping for easy tracking of hours
                </li>
                <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                    <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-black bg-blue-cobalt rounded-full" data-primary="indigo-400"><span class="text-sm font-bold text-white">✓</span></span>
                    Greater transparency in the timesheet approval
                </li>
            </ul>
        </div>
        <div class="box-border w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <div class="md:ml-10 md:mr-10 relative bg-white rounded-lg p-6 border border-gray-100 shadow-2xl">
                <div>
                    <div class="mt-6 mb-6 relative">
                        <div class="text-center">
                            <div class="text-center mt-2 py-2 leading-normal text-lg">
                                <div class="flex justify-center items-center">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-current text-black w-8 h-8">
                                            <title>Checkmark Circle</title>
                                            <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path>
                                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"></path>
                                        </svg>
                                    </span>
                                    <div class="font-normal text-xl ml-3">
                                        Pending Timesheet
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col text-center mt-4 ml-3">
                                <span class="text-gray-800 font-medium text-4xl">40 hrs</span>
                            </div>
                            <div class="text-center mt-2 py-2 leading-normal text-lg">John Smith <span class="ml-1">2/19</span></div>
                            <div class="text-center mt-1 py-2 leading-normal text-sm text-gray-800">
                                A PDF copy of the pending timesheet has been emailed to you.
                            </div>
                            <div class="mt-6">
                                <a href="#payroll" @click="approve()" class="block w-full md:px-10 py-4 text-base font-bold leading-6 text-white bg-blue-cobalt border border-transparent rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">Send me a Timesheet to Approve</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: ['approveHours'],

    methods: {
        approve() {
            window.App.event(window.App.analytics, 'freetimesheets_send_me_a_timesheet_click', { status: 'approve' });
            this.$emit('approveTimesheet');
        }
    }

}
</script>