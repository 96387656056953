<template>
    <div id="submit-timesheet" class="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 md:mb-20 md:flex-row max-w-7xl lg:px-16">
        <div class="box-border w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
            
            <div class="relative bg-white rounded-lg p-6 border border-gray-100 shadow-2xl">
                <div id="workday_div" class="relative mb-6 mt-6">

                    <!---->
                    <div class="text-xs ">
                        <!-- <div class="mt-4 sm:mt-0">
                            <div class="flex justify-between mx-2 px-2 py-4 font-medium sm:flex">
                                <div class="w-1/4 text-left">Day</div>
                                <div class="w-1/4 text-center">In</div>
                                <div class="w-1/4 text-center">Out</div>
                                <div class="w-1/4 text-center">Break</div>
                                <div class="w-1/4 text-right">Total</div>
                            </div>
                        </div> -->
                        
                        <div v-for="(item, index) in timesheet" :key="index">
                            <div @click="selectDay(item.id)" class="cursor-pointer mb-1 md:mb-2 rounded-lg shadow-md border md:mx-0" :class="[index == 0 && !selectedDays.includes(item.id) ? 'animate-pulse' : '']">
                                <div class="flex mx-4 px-2 py-2 md:py-4 items-center">
                                    <div class="w-1/4 text-left font-medium">{{ item.day }}</div>
                                    <div class="w-1/4 text-center" v-show="selectedDays.includes(item.id)">{{ item.in }}</div>
                                    <div class="w-1/4 text-center" v-show="selectedDays.includes(item.id)">{{ item.out }}</div>
                                    <div class="w-1/4 text-center" v-show="selectedDays.includes(item.id)">{{ item.break }}</div>
                                    <div class="w-1/4 text-right" v-show="selectedDays.includes(item.id)">{{ item.total }}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <div class="flex mx-2 py-4 font-medium">
                                <div class="w-1/4">Total hours</div>
                                <div class="w-1/4"></div>
                                <div class="w-1/4"></div>
                                <div class="w-1/4"></div>
                                <div class="w-1/4 text-right">{{ totalHours }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mt-5">
                        <a href="#approve-timesheet" @click="submit()" class="block w-full px-10 py-4 text-base font-bold leading-6 text-white bg-blue-cobalt border border-transparent rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">Submit</a>
                    </div> -->
                </div>
            </div>

        </div>
        <div class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none ml-10">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                Submit Timesheet
            </h2>
            <p class="pt-4 pb-4 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                if you're tired of paper timesheets and want to streamline the process while helping the environment, start submitting your timesheets online today.
            </p>
            <p class="pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                It's easy, efficient, and helps make a positive impact on our planet.
            </p>
            <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                    <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-cobalt rounded-full" data-primary="indigo-400"><span class="text-sm font-bold">✓</span></span>
                    Easy access from any device, anywhere, and at any time
                </li>
                <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                    <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-cobalt rounded-full" data-primary="indigo-400"><span class="text-sm font-bold">✓</span></span>
                    Automated calculations for accurate and efficient timesheet
                </li>
                <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                    <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-cobalt rounded-full" data-primary="indigo-400"><span class="text-sm font-bold">✓</span></span>
                    Faster approval and review process for quicker payment
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

    props: ['timesheet', 'selectedDays'],

    computed: {
        totalHours() {
            return this.timesheet.filter(item => this.selectedDays.includes(item.id)).reduce((acc_2, item) => {
                return acc_2 + parseFloat(item.total);
            }, 0);
        }
    },

    methods: {
        selectDay(id) {
            this.$emit('selectDay', id);
        },

        submit() {
            this.$emit('submitTimesheet');
        }
    }

}
</script>