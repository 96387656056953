<template>
  <div class="container-fluid bg-img-dark">
    <div class="container mx-auto px-5 py-12 md:pt-32 md:pb-16">

      <div class="flex flex-wrap items-center">
        <div class="flex md:w-1/2 items-center justify-center md:justify-end hidden md:flex" data-aos="fade-right">
          <img src="@/assets/images/timesheet-compressed.png" alt="" class="w-8/12 md:mr-16" loading="lazy">
        </div>
        <div class="md:w-1/2 mt-5 md:mt-0">
          <div class="mb-10 text-left headline-2 text-white">
            <h2>Timesheets your employees will actually love</h2>
          </div>

          <div class="flex items-center justify-center pb-5 md:hidden" data-aos="fade-left">
            <img src="@/assets/images/timesheet-compressed.png" alt="" class="w-8/12" loading="lazy">
          </div>

          <div class="flex">
            <div class="mt-1">
                <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                </span>
            </div>
            <div class="ml-4">
              <h3 class="home-subtitle-highlight text-white">Easy to submit </h3>
              <!-- <p class="home-subtitle">
                Using timesheets, employees can record their work hours, attach their reimbursement details, and add notes.
              </p> -->
            </div>
          </div>
          <div class="flex mt-6">
            <div class="mt-1">
                <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                </span>
            </div>
            <div class="ml-4">
              <h3 class="home-subtitle-highlight text-white">One-click Approval </h3>
              <!-- <p class="home-subtitle">
                Supervisors only have to approve timesheets with 1-Click for quick approval.
              </p> -->
            </div>
          </div>
          <div class="flex mt-6">
            <div class="mt-1">
                <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                </span>
            </div>
            <div class="ml-4">
              <h3 class="home-subtitle-highlight text-white">Shift Work</h3>
              <!-- <p class="home-subtitle">
                Complete startime and end time, rest we will take care! Yes, Temp Button does shift work.
              </p> -->
            </div>
          </div>
          <div class="flex mt-6">
            <div class="mt-1">
                <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                </span>
            </div>
            <div class="ml-4">
              <h3 class="home-subtitle-highlight text-white">Yes, It's On Mobile</h3>
              <!-- <p class="home-subtitle">
                Timesheets are mobile enabled for everyone. Just download the app and start using it!
              </p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center mt-12 md:mt-16">
        <button onclick="window.App.event(window.App.analytics, 'book_demo_click', { clicked: 'true' }); Calendly.initPopupWidget({url: 'https://calendly.com/mariotempbutton/demo-with-mario-temp-button'}); return false;" class="w-56 py-2 border-2 border-blue-700 bg-blue-700 focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-700-dark hover:bg-blue-700-dark transition-all duration-200 ease-in text-lg cursor-pointer">Book a Demo Now</button>
      </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>