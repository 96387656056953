<template>
    <div id="submit-timesheet" class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
        <div class="flex flex-col md:flex-row items-center -mx-3 gap-10">
            <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">

                <div class="bg-white rounded-lg p-6 border border-gray-100 shadow-2xl">
                    <div @click="show_instruction = false" v-show="show_instruction && !selectedDays.length > 0" class="absolute rounded-lg" id="instruction_top" style="background: rgba(0, 0, 0, .5); width: 100%; height: 10%; top: 5; left: 0;"></div>

                    <div @click="show_instruction = false" v-show="show_instruction && !selectedDays.length > 0" id="instruction_bottom" class="absolute rounded-lg" style="background: rgba(0, 0, 0, .5); width: 100%; height: 78%; top: 117px; left: 0;">
                        <div class="flex flex-col justify-center items-center text-white mt-5">
                            <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve" class="w-16 h-16 fill-current"><g><path d="M507.606,422.754l-86.508-86.508l42.427-42.426c3.676-3.676,5.187-8.993,3.992-14.053s-4.923-9.14-9.855-10.784   L203.104,184.13c-5.389-1.797-11.333-0.394-15.35,3.624c-4.018,4.018-5.421,9.96-3.624,15.35l84.853,254.559   c1.645,4.932,5.725,8.661,10.784,9.855c5.059,1.197,10.377-0.315,14.053-3.992l42.427-42.426l86.508,86.507   c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394l63.64-63.64C513.465,438.109,513.465,428.612,507.606,422.754z    M433.36,475.787l-86.508-86.507c-5.857-5.858-15.356-5.858-21.213,0l-35.871,35.871l-67.691-203.073l203.073,67.691   l-35.871,35.871c-5.853,5.852-5.858,15.356,0,21.213l86.508,86.508L433.36,475.787z"></path> <path d="M195,120c8.284,0,15-6.716,15-15V15c0-8.284-6.716-15-15-15s-15,6.716-15,15v90C180,113.284,186.716,120,195,120z"></path> <path d="M78.327,57.114c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l63.64,63.64   c5.857,5.858,15.356,5.858,21.213,0c5.858-5.858,5.858-15.355,0-21.213L78.327,57.114z"></path> <path d="M120.754,248.033l-63.64,63.64c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.858,15.356,5.858,21.213,0l63.64-63.64   c5.858-5.858,5.858-15.355,0-21.213C136.109,242.175,126.611,242.175,120.754,248.033z"></path> <path d="M269.246,141.967l63.64-63.64c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0l-63.64,63.64   c-5.858,5.858-5.858,15.355,0,21.213C253.89,147.825,263.389,147.825,269.246,141.967z"></path> <path d="M120,195c0-8.284-6.716-15-15-15H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h90C113.284,210,120,203.284,120,195z"></path></g></svg></span>
                            <span class="mt-5">Click on highlighted day to fill the timesheet</span>
                        </div>
                    </div>

                    <div class="text-xs ">
                        <!-- <div class="mt-4 sm:mt-0">
                            <div class="flex justify-between mx-2 px-2 py-4 font-medium sm:flex">
                                <div class="w-1/4 text-left">Day</div>
                                <div class="w-1/4 text-center">In</div>
                                <div class="w-1/4 text-center">Out</div>
                                <div class="w-1/4 text-center">Break</div>
                                <div class="w-1/4 text-right">Total</div>
                            </div>
                        </div> -->
                        
                        <div v-for="(item, index) in timesheet" :key="index">
                            <div @click="selectDay(item.id)" class="cursor-pointer mb-1 md:mb-2 rounded-lg shadow-md border md:mx-0" :class="[index == 1 && !selectedDays.includes(item.id) ? 'animate-pulse' : '']">
                                <div class="flex mx-4 px-2 py-2 md:py-4 items-center">
                                    <div class="w-12 text-left font-medium text-slate-900">{{ item.day_format }}</div>
                                    <div class="w-1/4 text-center">{{ selectedDays.includes(item.id) ? item.in_time : '' }}</div>
                                    <div class="w-1/4 text-center">{{ selectedDays.includes(item.id) ? item.out_time : '' }}</div>
                                    <div class="w-1/4 text-center">{{ selectedDays.includes(item.id) ? item.break_time : '' }}</div>
                                    <div class="w-1/4 text-right" v-show="selectedDays.includes(item.id)">{{ item.total }}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <div class="flex mx-2 py-4 font-medium">
                                <div class="w-1/4">Total hours</div>
                                <div class="w-1/4"></div>
                                <div class="w-1/4"></div>
                                <div class="w-1/4"></div>
                                <div class="w-1/4 text-right">{{ totalHours }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <a href="#approve-timesheet" @click="submit()" class="block w-full px-10 py-4 text-base font-bold leading-6 text-white text-center bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">Submit</a>
                    </div>
                </div>

            </div>
            <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
                <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                    Submit Timesheet
                </h2>
                <p class="pt-4 pb-1 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                    Tired of paper timesheets?
                </p>
                <p class="pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                    Want to streamline the process? 
                </p>
                <p class="pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                    Start submitting your timesheets on <span class="font-bold text-black">All-in-one</span> and Go Green
                </p>
                <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                    
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Accurate Shift Based Calculations</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Dynamic Expenses Billable/Non Billable </h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>PTO & Worked Holidays</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Beautiful and Crispy UX</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Work from Anywhere</h3>
                        </div>
                    </li>
                </ul>
                
            </div>
        </div>

        <popup-workday-create @update-timesheet="updateTimesheet"></popup-workday-create>
    </div>
</template>

<script>
import PopupWorkdayCreate from './PopupWorkdayCreate.vue';

export default {

    props: ['timesheet', 'selectedDays'],

    components: { PopupWorkdayCreate },

    data() {
        this.$emit('selectDay', 1);
        this.$emit('selectDay', 2);
        this.$emit('selectDay', 3);
        this.$emit('selectDay', 4);
        this.$emit('selectDay', 5);

        return {
            show_instruction: true,
            selectedWorkdayId: '', 
        }
    },

    computed: {
        totalHours() {
            return this.timesheet.filter(item => this.selectedDays.includes(item.id)).reduce((acc_2, item) => {
                return acc_2 + parseFloat(item.total);
            }, 0);
        }
    },

    methods: {
        selectDay(id) {
            //this.$emit('selectDay', id);

            this.show_instruction = false;
            
            let selected = this.timesheet.filter(item => item.id == id);
            this.selectedWorkdayId = id;
            
            this.$modal.show("create-workday-modal", {
              work_day: {
                date: selected[0]['day'],
                date_format: selected[0]['day_format'],
                day: "",
                holiday_name: "",
                is_holiday: null,
                month: "2/23",
                pay_type: "",
                workday: "",
              },
              day: selected[0]['day'],
              day_format: selected[0]['day_format'],
              in_time: selected[0]['in_time'],
              out_time: selected[0]['out_time'],
              break_time_shifts: selected[0]['break_time_shifts'],
              temp: 'John Smith',
              client: 'Holly Pearce',
            });
        },

        submit() {
            window.App.event(window.App.analytics, 'experience_submit_click', { status: 'true' });
            this.$emit('submitTimesheet');
        },

        updateTimesheet(updatedWorkday) {
            const index = this.timesheet.findIndex(item => item.id === this.selectedWorkdayId);

            this.timesheet[index].in_time = updatedWorkday.in_time;
            this.timesheet[index].out_time = updatedWorkday.out_time;
            this.timesheet[index].break_time = updatedWorkday.break_time;
            this.timesheet[index].break_time_shifts = updatedWorkday.break_time_shifts;
            this.timesheet[index].total = updatedWorkday.total_hours;
            
            this.$emit('selectDay', this.selectedWorkdayId);

            this.$modal.hide("create-workday-modal");
            this.selectedWorkdayId = "";

        },
    }

}
</script>