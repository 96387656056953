<template>
    <modal name="try-for-free-modal" :width="400" :height="'auto'" :scrollable="true" :adaptive="true" @before-open="beforeOpen" id="try-for-free-modal">
        <div class="mt-6 mb-6 px-6 relative text-black">
            <div>
                <a style="margin-top: -10px;" class="absolute right-0 top-0 block -mt-4 mr-2  text-grey hover:text-gray-600" href="javascript:void(0)" @click="$modal.hide('try-for-free-modal')"><svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 fill-current" viewBox="0 0 512 512"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"/></svg>
                </a>
                <div class="text-center pb-10">
                    <div class="font-bold text-2xl">Get Started</div>
                </div>
            </div>
            <div class="pb-4 pt-2">
                <div class="w-full">
                    <div>
                        <input placeholder="Name" id="name" type="text" class="w-full text-lg rounded-lg py-2 pl-5 md:mr-5 border-2" name="name" value="" v-model="name" required ref="nameInput">
                    </div>
                    <div>
                        <div class="w-full text-sm error_message pt-2" v-text="errors.get('name')"></div>
                    </div>
                </div>
            </div>

            <div class="mb-4">
                <div class="w-full">
                    <div>
                        <input placeholder="Email" id="email" v-model="email" type="email" class="w-full text-lg text-gray-900 rounded-lg py-2 pl-5 md:mr-5 border-2"  name="email" value="" required>
                    </div>
                    <div>
                        <div class="w-full text-sm error_message pt-2" v-text="errors.get('email')"></div>
                    </div>
                </div>
            </div>

            <div class="mt-12">
                <label class="w-full lg:w-1/4 font-medium lg:justify-end mb-1 lg:mb-0 mt-2"></label>
                <div class="w-full flex flex-row justify-center">
                    <div v-if="!loading" class="w-full flex items-center">
                        <button v-if="send_invoice != true" @click="submit" class="w-full px-10 py-4 text-base font-bold leading-6 text-white bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                            Send Me Timesheet
                        </button>
                        <button v-else @click="submit" class="w-full px-10 py-4 text-base font-bold leading-6 text-white bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                            Send Me A Sample Invoice
                        </button>
                    </div>
                    <loading class="py-2 px-4" :width="'24px'" :height="'30px'" v-if="loading" :show="loading"></loading>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    class Errors {
        constructor() {
            this.errors = {};
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field][0];
            }
        }


        record(errors) {
            this.errors = errors;
        }
    }

    export default {
        components: {},
        data () {
            return {
                name: '',
                email: '',
                role: 'supervisor',
                is_registered_from_demo: true,
                loading: false,
                errors: new Errors(),
                send_invoice: false
            }
        },

        computed: {},

        watch: {},

        created() {
            //setTimeout(() => this.focusOnNameInput(), 2000);
        },

        methods: {
            beforeOpen(event)
            {
                this.errors = new Errors();
                this.name = '';
                this.email = '';
                this.send_invoice = (event.params.send_invoice == true) ;

                console.log(event.params.send_invoice);
                setTimeout(() => this.focusOnNameInput(), 1000);
            },

            focusOnNameInput() {
                const nameInputRef = this.$refs.nameInput;
                nameInputRef.focus();
            },

            submit() {
                this.loading = true;
                //let url = 'http://timesheet.local.com';
                let url = 'https://timesheet-stage.tempbutton.com';
                window.axios.post(url + '/api/auth/try_me', {
                    name: this.name,
                    email: this.email,
                    is_registered_from_demo: this.is_registered_from_demo,
                    role: this.role,
                    send_invoice : this.send_invoice
                }).then(({data}) => {
                    if(data.status == true) {
                        this.$emit('success-sign-up');
                        this.$modal.hide('try-for-free-modal');
                    }
                    this.loading = false;
                    this.cleanErrors();
                }).catch(error => {
                    this.loading = false;
                    this.errors.record(error.response.data.errors)
                });
            },

            cleanErrors() {
                this.errors = new Errors();
            }
        }
    }
</script>

<style>
.right-0 {
  right: 0px;
}
#try-for-free-modal .vm--modal {
    background-color: #fff;
    border-radius: 20px;
}
.error_message {
    color: rgba(153,27,27, 1);
}
</style>
