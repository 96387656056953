<template>
<div id="steps">
    <div class="container-fluid bg-img-light">
      <div class="container mx-auto px-5 py-12 md:pt-32 md:pb-16">
        <div class="flex flex-wrap items-center">
          <div class="md:w-1/2 mt-5 md:mt-0">
            <div class="mb-10 text-left headline-2 text-white">
              <h2>Easy onboarding of employees</h2>
            </div>
            <div class="flex items-center justify-center pb-5 md:hidden" data-aos="fade-left">
              <img src="@/assets/images/onboarding-compressed.png" alt="" class="w-8/12" loading="lazy">
            </div>
            <div class="flex">
              <div class="mt-1">
                  <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                  </span>
              </div>
              <div class="ml-4">
                <h3 class="home-subtitle-highlight text-white">Quick onboarding </h3>
                <!-- <p class="home-subtitle">
                  Employees on-board themselves by adding details such as federal and state tax forms (ie., i9 w4) and banking info.
                </p> -->
              </div>
            </div>
            <div class="flex mt-6">
              <div class="mt-1">
                  <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                  </span>
              </div>
              <div class="ml-4">
                <h3 class="home-subtitle-highlight text-white">Employee Driven</h3>
                <!-- <p class="home-subtitle">
                  This allows employees to conveniently edit and update their banking information, especially when they move.
                </p> -->
              </div>
            </div>
            <div class="flex mt-6">
              <div class="mt-1">
                  <span class="bg-blue-700 text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-4 h-4" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg>
                  </span>
              </div>
              <div class="ml-4">
                <h3 class="home-subtitle-highlight text-white">Update from anywhere</h3>
                <!-- <p class="home-subtitle">
                  Regardless of where employees work (office or remote location), work locations updated via timesheets and tax deductions (withholdings) are automatically updated.
                </p> -->
              </div>
            </div>
          </div>
          <div class="flex md:w-1/2 items-center justify-center md:justify-end hidden md:flex" data-aos="fade-left">
            <img src="@/assets/images/onboarding-compressed.png" alt="" class="w-8/12 md:mr-16" loading="lazy">
          </div>
        </div>

        <div class="flex items-center justify-center mt-12 md:mt-16">
          <button onclick="window.App.event(window.App.analytics, 'book_demo_click', { clicked: 'true' }); Calendly.initPopupWidget({url: 'https://calendly.com/mariotempbutton/demo-with-mario-temp-button'}); return false;" class="w-56 py-2 border-2 border-blue-700 bg-blue-700 focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-700-dark hover:bg-blue-700-dark transition-all duration-200 ease-in text-lg cursor-pointer">Book a Demo Now</button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
    
}
</script>