<template>
    <modal name="create-workday-modal" :classes="'rounded-lg'" :width="350" :height="'auto'" @before-open="beforeOpen">
        <div class="mt-8 mb-8 px-6 relative">
            <div class="text-center">
                <popup-header
                    :title="day_format"
                    :second-title="(client ? ' ' + client : '')"
                    :model-id="'create-workday-modal'"
                    :show-close-icon="'true'"
                    :title-class="'font-bold'"></popup-header>

                <div class="py-2 mt-8">
                    <select-in-out-break-time
                        @get_total_hour="getTotalHour"
                        :in-time.sync="in_time"
                        :out-time.sync="out_time"
                        :break-time-shifts="break_time_shifts"
                        :client="client"
                        :day-format="day_format"
                        :breakdown-shift="true"
                        :errors="errors"
                    ></select-in-out-break-time>
                </div>

                <div class="bg-red-400est py-2 px-4 border border-red my-2" v-if="errors.get('break_times')">
                    <span class="text-sm text-red" v-text="errors.get('break_times')"></span>
                </div>

                <div class="bg-red-400est py-2 px-4 border border-red my-2" v-if="errors.get('total_hours')">
                    <span class="text-sm text-red" v-text="errors.get('total_hours')"></span>
                </div>

                <div class="px-2 my-2 text-left" v-if="errors.get('sick_day_client')">
                    <span class="text-sm text-red-800" v-text="errors.get('sick_day_client')"></span>
                </div>

                <div class="px-2 my-2 text-left" v-if="errors.get('day')">
                    <span class="text-sm text-red-800" v-text="errors.get('day')"></span>
                </div>

                <div class="mt-4">
                    <button v-if="!loading" class="block w-full px-10 py-3 text-base font-bold leading-6 text-white bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600" @click="create" :disabled="!createWorkdayAllow" :class="!createWorkdayAllow ? 'cursor-not-allowed' : ''">
                        Create
                    </button>
                    <div class="flex justify-center">
                        <loading :width="'24px'" :height="'30px'" :show="loading"></loading>
                    </div>
                </div>
            </div>
        </div>
        <popup-workday-confirm @create-workday="create"></popup-workday-confirm>
    </modal>
</template>

<script>
    import moment from 'moment';

    import PopupHeader from "./PopupHeader";
    import SelectInOutBreakTime from './SelectInOutBreakTime.vue'
    import PopupWorkdayConfirm from "./PopupWorkdayConfirm";

    class Errors {

        constructor() {
            this.errors = {};
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field][0];
            }
        }


        record(errors) {
            this.errors = errors;
        }
    }

    export default {
        components: { PopupHeader, SelectInOutBreakTime, PopupWorkdayConfirm },

        data () {
            return {
                workDay: '',
                day: '',
                day_format: '',

                client: null,
                temp: null,

                in_time:  '9:30 AM',
                out_time: '',
                type: 'workday',

                break_time_shifts: {
                    shift_1: '00:00',
                    shift_2: '00:00',
                    shift_3: '00:00'
                },
                
                is_holiday: false,
                holiday_name: '',
                holiday_pay_type: '',

                comment: '',
                loading: false,
                errors: new Errors(),
                createWorkdayAllow: true
            }
        },

        methods: {
            beforeOpen(event) {
                this.workDay = event.params.work_day ? event.params.work_day : '';
                this.day = event.params.day;
                this.day_format = event.params.day_format;
                this.temp = event.params.temp;
                this.client = event.params.client;
                this.type = 'workday';

                this.comment = '';

                this.loading = false;
                this.errors = new Errors();

                this.setIsHoliday();
                this.in_time = event.params.in_time;
                this.out_time = event.params.out_time;
                this.break_time_shifts = event.params.break_time_shifts;
            },

            create() {
                if (this.holiday_name && (this.client && this.client.props.holiday_pto)) {
                    this.$modal.show('workday-holiday-type-modal', {
                        holiday_name: this.holiday_name,
                        holiday_pay_type: this.holiday_pay_type
                    });
                } else if (this.holiday_name) {
                    this.createWorkday('worked');
                } else {
                    this.createWorkday();
                }
            },

            saveHoliday(holiday_type) {
                this.createWorkday(holiday_type);
            },

            createWorkday() {
                this.loading = true;

                this.$emit('update-timesheet', {
                    in_time: this.in_time,
                    out_time: this.out_time,
                    break_time: window.getTotalBreakTimeHour(this.break_time_shifts),
                    total_hours: window.getTotalHour(this.in_time, this.out_time, this.break_time_shifts),
                    break_time_shifts: this.break_time_shifts,
                });

                this.loading = false;
            },

            confirm_popup() {
                var workdayPopup = localStorage.getItem("workday_popup");

                if(this.day > moment().format('YYYY-MM-DD') && workdayPopup != 'true')
                {
                    this.$modal.show('workday-confirm-modal', {
                        day: this.day,
                        temp: this.temp,
                        client: this.client,
                        in_time: this.in_time,
                        out_time: this.out_time,
                        break_time_shifts: this.break_time_shifts,
                        comment: this.comment,
                    });

                    return false;
                }

                this.create();
            },

            setIsHoliday() {
                if (this.client && this.client.is_client_internal == true) {
                    this.type = 'sickday';
                } else if (this.workDay.is_holiday) {
                    this.type = 'holiday';
                } else {
                    this.type = 'workday';
                }
                this.holiday_name = this.workDay.is_holiday ? `${this.workDay.holiday_name}` : '';
                this.holiday_pay_type = this.workDay.is_holiday ? `${this.workDay.pay_type}` : '';
            },

            getTotalHour (total_hour) {
                if(total_hour < 0) {
                    this.createWorkdayAllow = false
                } else {
                    this.createWorkdayAllow = true
                }
            }
        }
    }
</script>