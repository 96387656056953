<template>
    <modal name="general-message-modal" :width="330" :adaptive="true" :height="'auto'" @before-open="beforeOpen" id="success_sign_up_modal">
        <div class="mt-10 px-5 pb-10 text-center text-black">
            <div>
                <a style="margin-top: 12px;" class="absolute right-0 top-0 block -mt-4 mr-2  text-grey hover:text-gray-600" href="javascript:void(0)" @click="$modal.hide('general-message-modal')"><svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 fill-current" viewBox="0 0 512 512"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"/></svg>
                </a>
            </div>
            <div class="text-black flex justify-center">
                <svg class="w-24 h-24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <div class="text-xl mt-4">{{ this.message }}</div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: 'PopupMessage',
        components: {},
        data () {
            return {
                title: '',
                subtitle: '',
                message: '',
                loading: false
            }
        },

        methods: {
            beforeOpen(event)
            {
                this.title = event.params.title;
                this.subtitle = event.params.subtitle;
                this.message = event.params.message;
            }
        }
    }
</script>

<style>
#success_sign_up_modal .vm--modal {
    background-color: #fff;
    border-radius: 20px;
}
</style>