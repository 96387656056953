<template>
  <div id="app" class="overflow-x-hidden md:overflow-auto bg-black text-black" v-if="selected_page === 'home' || selected_page === 'privacy-policy' || selected_page === 'terms-and-conditions' ">
    <!-- Top Navigation -->
    <header-section></header-section>

    <home v-if="selected_page === 'home'"></home>
    <privacy-policy v-if="selected_page === 'privacy-policy'"></privacy-policy>
    <terms-and-conditions v-if="selected_page === 'terms-and-conditions'"></terms-and-conditions>

    <!-- Footer Section -->
    <footer-section></footer-section>

    <!-- Signup Modal -->
    <signupModal></signupModal>
  </div>

  <div id="app" v-else-if="selected_page === 'landing-page-v2' || selected_page === 'landing-page-v3' || selected_page === 'landing-page-v4' || selected_page === 'freetimesheets'">
    <landing-page-2 v-if="selected_page === 'landing-page-v2'"></landing-page-2>
    <landing-page-3 v-if="selected_page === 'landing-page-v3'"></landing-page-3>
    <landing-page-3 v-if="selected_page === 'freetimesheets'"></landing-page-3>
    <landing-page-4 v-if="selected_page === 'landing-page-v4'"></landing-page-4>
  </div>

</template>

<script>

import footerSection from './sections/new/footer.vue';
import headerSection from './sections/header.vue';
import signupModal from './components/signup.modal.vue';

import home from './sections/new/home.vue';
import privacyPolicy from "./sections/new/privacy-policy";
import termsAndConditions from "./sections/new/terms-and-conditions";
import landingPage2 from "./landing_page_2/index";
import landingPage3 from "./landing_page_3/index";
import landingPage4 from "./landing_page_4/index";

export default {
  components: { home, privacyPolicy, termsAndConditions, headerSection, footerSection,  signupModal, landingPage2, landingPage3, landingPage4},
  name: 'App',
  data() {
    return {
      selected_page: 'home',
      scrollPosition: null,
      navHeight: 'md:py-3'
    }
  },

  mounted() {
    //window.addEventListener('scroll', this.updateScroll);

    let pathname = window.location.pathname;
    if (pathname === '/privacy-policy') {
      this.selectPage('privacy-policy');
    } else if (pathname === '/terms-and-conditions') {
      this.selectPage('terms-and-conditions');
    } else if (pathname === '/freetimesheets') {
      this.selectPage('landing-page-v3');
    }  else if (pathname === '/experience') {
      this.selectPage('landing-page-v2');
    }else if (pathname === '/landing-page-v2') {
      this.selectPage('landing-page-v2');
    } else if (pathname === '/landing-page-v3') {
      this.selectPage('landing-page-v3');
    } else if (pathname === '/landing-page-v4') {
      this.selectPage('landing-page-v4');
    }
  },

  watch: {
    scrollPosition (val) {
      if (val >= 0 && val <= 100) {
        this.navHeight = 'md:py-1';
      } else if (val >= 101 && val <= 200) {
        this.navHeight = 'md:py-1';
      } else if (val >= 201 && val <= 300) {
        this.navHeight = 'md:py-0';
      } else {
        this.navHeight = 'md:py-0';
      }
    }
  },

  methods: {
    openSignupModal() {
      this.$modal.show('signup-modal');
    },

    toogleNavMobile(e) {
      var nav = document.querySelector('#navMobile');
      nav.classList.toggle('hidden');
      e.preventDefault();
    },

    updateScroll() {
      this.scrollPosition = window.scrollY
    },

    selectPage(page_name) {
      this.selected_page = page_name;
    }

  },
  metaInfo() {
    return {
      title: 'Temp Button', // Sets the <title> and `og:title`
      meta: [
        {
          name: 'description',
          content: 'TempButton - All in One Platform | Onboard, Timesheets, Payroll and Invoicing', // Sets the <meta name="description"> and `og:description`
        },
        {
          property: 'og:image',
          content: 'https://tempbutton.com/meta/meta.jpg', // Sets the `og:image` property
        },
        // You can add more meta tags here if needed
      ],
    };
  },
}
</script>

<style src="@/assets/app.css">
