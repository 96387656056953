import moment from 'moment-timezone';

window.getTimeRange = function () {
    let items = [];
    new Array(24).fill().forEach((acc, index) => {
        items.push(moment({ hour: index }).format('h:mm A'));
        items.push(moment({ hour: index, minute: 15 }).format('h:mm A'));
        items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
        items.push(moment({ hour: index, minute: 45 }).format('h:mm A'));
    });
    return items;
}


window.getBreakTimeRange = function () {
    let times = [];

    times.push({ key: '00:00', value: '' });
    times.push({ key: '00:15', value: '00:15' });
    times.push({ key: '00:30', value: '00:30' });
    times.push({ key: '00:45', value: '00:45' });
    times.push({ key: '01:00', value: '01:00' });
    times.push({ key: '01:15', value: '01:15' });
    times.push({ key: '01:30', value: '01:30' });
    times.push({ key: '01:45', value: '01:45' });
    times.push({ key: '02:00', value: '02:00' });
    times.push({ key: '02:15', value: '02:15' });
    times.push({ key: '02:30', value: '02:30' });
    times.push({ key: '02:45', value: '02:45' });
    times.push({ key: '03:00', value: '03:00' });
    times.push({ key: '03:15', value: '03:15' });
    times.push({ key: '03:30', value: '03:30' });
    times.push({ key: '03:45', value: '03:45' });

    return times;
},



    window.getOutTimeRange = function (in_time) {
        let start = moment(in_time, 'h:mm A').add(15, 'minutes');
        let end = moment(in_time, 'h:mm A').add(23, 'hours');

        let items = [];
        items.push('');
        for (let m = moment(start); m.isBefore(end); m.add(15, 'minutes')) {
            items.push(m.format('h:mm A'));
        }

        return items;
    }



window.addTimeToString = function (timeString, addHours, addMinutes) {
    // The third argument is optional.
    if (addMinutes === undefined) {
        addMinutes = 0;
    }
    // Parse the time string. Extract hours, minutes, and am/pm.
    var match = /(\d+):(\d+)\s+(\w+)/.exec(timeString),
        hours = parseInt(match[1], 10) % 12,
        minutes = parseInt(match[2], 10),
        modifier = match[3].toLowerCase();
    // Convert the given time into minutes. Add the desired amount.
    if (modifier[0] == 'p') {
        hours += 12;
    }
    var newMinutes = (hours + addHours) * 60 + minutes + addMinutes,
        newHours = Math.floor(newMinutes / 60) % 24;
    // Now figure out the components of the new date string.
    newMinutes %= 60;
    var newModifier = (newHours < 12 ? 'AM' : 'PM'),
        hours12 = (newHours < 12 ? newHours : newHours % 12);
    if (hours12 == 0) {
        hours12 = 12;
    }
    // Glue it all together.
    var minuteString = (newMinutes >= 10 ? '' : '0') + newMinutes;
    return hours12 + ':' + minuteString + ' ' + newModifier;
}


window.getTotalHour = function (in_time, out_time, break_time, time_ranges = []) {
    let value_start = in_time;
    let value_end = out_time;
    let totalBreakTimeHour = 0;

    /*if (break_time) {
        value_break_hour = parseInt(break_time.split(':')[0]);
        value_break_min = parseInt(break_time.split(':')[1]);
    }*/

    if (break_time) {
        totalBreakTimeHour = Object.values(break_time).map((item) => {
            if (item) {
                let value_break_hour = parseInt(item.split(':')[0]);
                let value_break_min = parseInt(item.split(':')[1]);

                return value_break_hour + value_break_min / 60;
            }
            return 0;
        }).reduce((acc, item) => acc + item, 0);

    }


    let start_search, founded = false;
    let hrs = 0;

    if (time_ranges.length === 0) {
        time_ranges = window.getTimeRange();
    }

    for (let i = 0; i < time_ranges.length - 1; i++) {
        let val = time_ranges[i].label;

        if (val === value_start) {
            start_search = true;
        }

        if (start_search && !founded) {
            if (val === value_end) {
                start_search = false;
                founded = true;
            } else {
                hrs += 0.25;
            }
        }
    }

    if (!founded) {
        for (let i = 0; i < time_ranges.length - 1; i++) {
            let val = time_ranges[i].label;
            if (!founded) {
                if (val === value_end) {
                    founded = true;
                } else {
                    hrs += 0.25;
                }
            }
        }
    }

    var timeStart = new Date("01/01/2007 " + in_time);
    var timeEnd = new Date("01/01/2007 " + out_time);

    var diff = (timeEnd - timeStart) / 60000; //dividing by seconds and milliseconds

    var minutes = diff % 60;
    var hours = (diff - minutes) / 60;

    hours += (hours < 0) ? 24 : 0;

    hrs = hours + minutes / 60;
    if (hrs > 0 && hrs <= 24) {
        return hrs - totalBreakTimeHour;

    }

    return 0;
}

window.getTotalBreakTimeHour = function (break_time) {
    let totalBreakTimeHour = 0;

    if (break_time) {
        totalBreakTimeHour = Object.values(break_time).map((item) => {
            if (item) {
                let value_break_hour = parseInt(item.split(':')[0]);
                let value_break_min = parseInt(item.split(':')[1]);

                return value_break_hour + value_break_min / 60;
            }
            return 0;
        }).reduce((acc, item) => acc + item, 0);

        return totalBreakTimeHour;
    }

    return 0;
}

window.getQueryVariable = function (variable) {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}