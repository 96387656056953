<template>
    <div id="payroll" class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
        <div class="flex flex-col md:flex-row items-center -mx-3 gap-10">
            <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">

                <div class="bg-white rounded-lg p-6 border border-gray-100 shadow-2xl">
                    <div class="flex flex-col md:flex-row justify-between items-top gap-2 px-5 py-2 rounded-lg border text-md">
                        <div class="w-16 hidden md:block">John Smith</div>
                        <div class="flex flex-col justify-start text-sm hidden md:block">
                            <div>{{ payroll ? totalHours - totalOTHours : 0 }} hrs</div>
                        </div>
                        <div class="flex flex-col justify-start text-sm hidden md:block">
                            <div>$ {{ payroll ? (payRate).toFixed(2) : '0.00' }}</div>
                        </div>
                        <div class="flex flex-col justify-start text-sm hidden md:block">
                            <div>$ {{ payroll ? (totalPayableAmount).toFixed(2) : '0.00' }}</div>
                        </div>
                        
                        <div class="text-center md:hidden">Jack Smith</div>
                        <div class="flex flex-row justify-between items-center md:hidden">  
                            <div class="flex flex-col justify-start text-sm">
                                <div>{{ payroll ? totalHours - totalOTHours : 0 }} hrs</div>
                            </div>
                            <div class="flex flex-col justify-start text-sm">
                                <div>$ {{ payroll ? (payRate).toFixed(2) : '0.00' }}</div>
                            </div>
                            <div class="flex flex-col justify-start text-sm">
                                <div>$ {{ payroll ? (totalPayableAmount).toFixed(2) : '0.00' }}</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="totalOTHours" class="flex flex-col md:flex-row justify-between items-top gap-2 px-5 py-2 rounded-lg border text-md mt-2">
                        <div class="w-16 hidden md:block">OT</div>
                        <div class="flex flex-col justify-start text-sm hidden md:block">
                            <div>{{ payroll ? totalOTHours : 0 }} hrs</div>
                        </div>
                        <div class="flex flex-col justify-start text-sm hidden md:block">
                            <div>$ {{ payroll ? (payRate * 1.5).toFixed(2) : '0.00' }}</div>
                        </div>
                        <div class="flex flex-col justify-start text-sm hidden md:block">
                            <div>$ {{ payroll ? (totalOTPayableAmount).toFixed(2) : '0.00' }}</div>
                        </div>
                        
                        <div class="text-center md:hidden">OT</div>
                        <div class="flex flex-row justify-between items-center md:hidden">  
                            <div class="flex flex-col justify-start text-sm">
                                <div>{{ payroll ? totalOTHours : 0 }} hrs</div>
                            </div>
                            <div class="flex flex-col justify-start text-sm">
                                <div>$ {{ payroll ? (payRate * 1.5).toFixed(2) : '0.00' }}</div>
                            </div>
                            <div class="flex flex-col justify-start text-sm">
                                <div>$ {{ payroll ? (totalOTPayableAmount).toFixed(2) : '0.00' }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between items-top gap-2 px-5 py-2 rounded-lg border text-md mt-5">
                        <div>Total</div>
                        <div class="text-sm">{{ payroll ? totalHours : 0 }} hrs</div>
                        <div class="text-sm">$ {{ payroll ? (totalPayableAmount + totalOTPayableAmount).toFixed(2) : '0.00' }}</div>
                    </div>
                    <div class="mt-5">
                        <a href="#invoice" @click="submitPayroll()" class="block w-full px-10 py-4 text-base font-bold leading-6 text-white text-center bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"><span>Pay Payroll</span></a>
                    </div>
                </div>
                
            </div>
            <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
                <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                    Payroll
                </h2>
                <p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                    Simplifies tax calculations and reporting, ensuring compliance with local, state, and federal laws.
                </p>
                <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>100% Secure </h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Easy to use</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Fully Customisable</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Benefits</h3>
                        </div>
                    </li>
                    <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <div class="flex justify-start items-center gap-1">    
                            <span class="text-blue-600 mr-2 inline-flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" /></svg>
                            </span>
                            <h3>Deductions</h3>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['payroll', 'payRate', 'totalHours', 'totalOTHours'],

    computed: {
        totalPayableAmount() {
            return this.payRate * (this.totalHours - this.totalOTHours);
        }, 
        totalOTPayableAmount() {
            return this.payRate * 1.5 * this.totalOTHours;
        }
    },

    methods: {
        submitPayroll() {
            window.App.event(window.App.analytics, 'experience_pay_payroll_click', { status: 'true' });
            this.$emit('submitPayroll');
        }
    }
}
</script>