<template>
    <div class="text-black">
        <section class="w-full px-6 pb-12 antialiased bg-white">
            <div class="mx-auto max-w-7xl">
                <nav class="relative z-50 h-24 select-none">
                    <div class="container relative flex flex-wrap items-center justify-between h-24 mx-auto overflow-hidden font-medium border-b border-gray-200 md:overflow-visible lg:justify-center sm:px-4 md:px-2 lg:px-0">
                        <div class="flex items-center justify-start w-1/4 h-full pr-4">
                            <a href="#_" class="inline-block py-4 md:py-0">
                                <span class="p-1 text-3xl font-black leading-none text-gray-900">TempButton</span>
                            </a>
                        </div>
                        <div class="top-0 left-0 items-start w-full h-full p-4 text-sm bg-gray-900 bg-opacity-50 md:items-center md:w-3/4 md:absolute lg:text-base md:bg-transparent md:p-0 md:relative md:flex" :class="{'flex fixed': showMenu, 'hidden': !showMenu }">
                            <div class="flex-col w-full h-auto overflow-hidden bg-white rounded-lg md:bg-transparent md:overflow-visible md:rounded-none md:relative md:flex md:flex-row">
                                <a href="#_" class="inline-flex items-center block w-auto h-16 px-6 text-xl font-black leading-none text-gray-900 md:hidden">TempButton</a>
                                <div class="flex flex-col items-start justify-center w-full space-x-6 text-center lg:space-x-8 md:w-2/3 md:mt-0 md:flex-row md:items-center">
                                </div>
                                <div class="flex flex-col items-start justify-end w-full pt-4 md:items-center md:w-1/3 md:flex-row md:py-0">
                                    <a onclick="window.App.event(window.App.analytics, 'experience_top_login_click', { status: 'true' });" href="https://timesheet.tempbutton.com/login" target="_blank" class="w-full px-6 md:px-3 py-2 mr-0 text-gray-700 md:mr-2 lg:mr-3 md:w-auto">Sign In</a>
                                    <a onclick="window.App.event(window.App.analytics, 'experience_top_book_demo_click', { clicked: 'true' }); Calendly.initPopupWidget({url: 'https://calendly.com/mariotempbutton/demo-with-mario-temp-button'});  return false;"
                                        href="#_"
                                        class="inline-flex items-center w-full px-6 py-3 text-sm font-bold leading-4 text-white bg-blue-600 md:px-3 md:w-auto md:rounded-lg lg:px-5 hover:bg-blue-500 focus:outline-none md:focus:ring-2 focus:ring-0 focus:ring-offset-2 focus:ring-blue-600"
                                    >
                                        Book A Demo
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div @click="showMenu = !showMenu" class="absolute right-0 flex flex-col items-center items-end justify-center w-10 h-10 bg-white rounded-full cursor-pointer md:hidden hover:bg-gray-100">
                            <svg class="w-6 h-6 text-gray-700" v-show="!showMenu" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                            <svg class="w-6 h-6 text-gray-700" v-show="showMenu" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </div>
                    </div>
                </nav>
                <div class="container max-w-lg px-4 py-32 mx-auto mt-px text-left md:max-w-none md:text-center">
                    <h1 class="text-4xl font-extrabold leading-10 tracking-tight text-left text-gray-900 text-center md:text-center sm:leading-none md:text-6xl lg:text-7xl">
                        Staffing, 
                    </h1>
                    <h1 class="text-4xl font-extrabold leading-10 tracking-tight text-left text-blue-600 text-center md:text-center sm:leading-none md:text-6xl lg:text-7xl">
                        Made Easy
                    </h1>
                    <div class="mx-auto mt-5 text-gray-500 md:mt-12 md:max-w-lg text-center lg:text-lg">Revolutionize your staffing with our <span class="font-bold text-black">All-in-one</span> solution, in just <span class="font-bold text-black">3 minutes</span> a week</div>
                    <div class="flex flex-col items-center mt-12 text-center">
                        <span class="relative inline-flex w-full md:w-auto">
                            <!-- <a @click="showDialogBox"
                                href="#_"
                                type="button"
                                class="inline-flex items-center justify-center w-full px-10 py-4 text-base font-bold leading-6 text-white bg-blue-600 border border-transparent rounded-lg md:w-auto hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                            >
                                Get Started
                            </a> -->
                        </span>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="py-20 bg-gray-50">
            <div class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
                <div class="flex flex-wrap items-center -mx-3">
                    <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
                        <div class="w-full lg:max-w-md">
                            <h2 class="mb-4 text-3xl font-bold leading-tight tracking-tight font-heading">Highlights of TempButton</h2>
                            <!-- <p class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">We are a leading provider of staffing solutions for businesses of all sizes. Whether you're looking to fill a temporary or permanent role.</p>
                            <p class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">Our tools will help you with the following:</p> -->
                            <ul>
                                <li class="flex items-center py-2 space-x-4 xl:py-3">
                                    <svg class="w-8 h-8 text-pink-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                                        ></path>
                                    </svg>
                                    <span class="font-medium text-gray-500">50 State Payroll</span>
                                </li>
                                <li class="flex items-center py-2 space-x-4 xl:py-3">
                                    <svg class="w-8 h-8 text-pink-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                                        ></path>
                                    </svg>
                                    <span class="font-medium text-gray-500">Built-in Sicktime compliance</span>
                                </li>
                                <li class="flex items-center py-2 space-x-4 xl:py-3">
                                    <svg class="w-8 h-8 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                                    </svg>
                                    <span class="font-medium text-gray-500">Invoices - the way your clients want</span>
                                </li>
                                <li class="flex items-center py-2 space-x-4 xl:py-3">
                                    <svg class="w-8 h-8 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                                    </svg>
                                    <span class="font-medium text-gray-500">Seamless QuickBooks integration</span>
                                </li>
                                <li class="flex items-center py-2 space-x-4 xl:py-3">
                                    <svg class="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                        ></path>
                                    </svg>
                                    <span class="font-medium text-gray-500">OT compliance engine</span>
                                </li>

                                <li class="flex items-center py-2 space-x-4 xl:py-3">
                                    <svg class="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                        ></path>
                                    </svg>
                                    <span class="font-medium text-gray-500">100% Secure</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
                        <img class="mx-auto sm:max-w-sm lg:max-w-full" src="@/assets/images/financial-planning.png" alt="feature image" />
                    </div>
                </div>
            </div>
        </section>

        <section class="py-20 ">
            <timesheetSubmit :timesheet="timesheet" :selectedDays="selectedDays" @selectDay="selectDay" @submitTimesheet="submitTimesheet"></timesheetSubmit>
        </section>

        <section class="py-20 ">
            <timesheetApprove :approveHours="totalHours" @approveTimesheet="approveTimesheet"></timesheetApprove>
        </section>

        <section class="py-20 ">
            <timesheetPayroll :payroll="payroll" :payRate="payRate" :totalHours="totalHours" :totalOTHours="totalOTHours" @submitPayroll="submitPayroll"></timesheetPayroll>
        </section>

        <section class="py-20 ">
            <timesheetInvoices :invoice="invoice" :billRate="billRate" :totalHours="totalHours" :totalOTHours="totalOTHours" @submitInvoice="submitInvoice" @sendmeinvoice="sendmeinvoice"></timesheetInvoices>
        </section>
        
        <section class="w-full px-8 py-16 bg-gray-100 xl:px-8">
            <div id="talktous" class="max-w-5xl mx-auto">
                <div class="flex flex-col items-center md:flex-row">
                    <div class="w-full space-y-5 md:w-3/5 md:pr-16">
                        <h2 class="text-2xl font-extrabold leading-none text-black sm:text-3xl md:text-4xl">
                            We're more than just a business, we're your partner. Contact us.
                        </h2>
                    </div>
                    <div class="w-full mt-16 md:mt-0 md:w-2/5">
                        <div class="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7" data-rounded="rounded-lg" data-rounded-max="rounded-full">
                            <h3 class="mb-6 text-2xl font-medium text-center">Talk to Us</h3>
                            <!-- <input type="text" v-model="email" class="block w-full px-4 py-3 border border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none" placeholder="Email address" /> -->
                            <!-- <div class="w-full text-sm error_message mb-4" v-text="errors.get('email')"></div> -->

                            <div class="block mt-4">
                                <div v-if="!loading" class="w-full flex items-center">
                                    <button onclick=" window.App.event(window.App.analytics, 'experience_bottom_book_demo_click', { status: 'true' }); Calendly.initPopupWidget({url: 'https://calendly.com/mariotempbutton/demo-with-mario-temp-button'});  return false;" class="w-full px-10 py-4 text-base font-bold leading-6 text-white bg-blue-600 border border-transparent rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600" data-primary="blue-600" data-rounded="rounded-lg">Book A Demo</button>
                                </div>
                                <loading class="py-4 px-4" :width="'24px'" :height="'27px'" v-if="loading" :show="loading"></loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg-white">
            <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
            
                <div class="flex justify-center mt-8 space-x-6">
                    <a href="https://www.facebook.com/Temp-Button-102048012350839" target="_blank" class="text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Facebook</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                fill-rule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/tempbutton/" target="_blank" class="text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Instagram</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                fill-rule="evenodd"
                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>
                    <a href="https://twitter.com/tempbutton" target="_blank" class="text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Twitter</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                            />
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/80770325" target="_blank" class="text-gray-400 hover:text-gray-500">
                        <span class="sr-only">LinkedIn</span>
                        <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5 hover:text-gray-600" viewBox="0 0 24 24">
                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" ></path>
                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                        </svg>
                    </a>
                </div>
                <p class="mt-8 text-base leading-6 text-center text-gray-400">
                    &copy; 2023 TempButton. All rights reserved.
                </p>
            </div>
        </section>

        <popup-try-for-free @success-sign-up="showConfirmationDialog"></popup-try-for-free>
        <popup-message></popup-message>
    </div>
</template>
<script>

class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }


    record(errors) {
        this.errors = errors;
    }
}

import timesheetSubmit from './components/timesheetSubmit.vue';
import timesheetApprove from './components/timesheetApprove.vue';
import timesheetPayroll from './components/timesheetPayroll.vue';
import timesheetInvoices from './components/timesheetInvoices.vue';

import popupTryForFree from './components/popupTryForFree.vue';
import PopupMessage from './components/PopupMessage.vue';

export default {
    
    components: { timesheetSubmit, timesheetApprove, timesheetPayroll, timesheetInvoices, popupTryForFree, PopupMessage },

    data() {
        return {
            showMenu: false,
            selectedDays: [],
            timesheet: [
                {
                    id: 1,
                    day: '2023-02-20',
                    day_format: 'Mon 2/20',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 0.5,
                    break_time_shifts: {
                        shift_1: '00:30',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '7.5',
                }, {
                    id: 2,
                    day: '2023-02-21',
                    day_format: 'Tue 2/21',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 0.5,
                    break_time_shifts: {
                        shift_1: '00:30',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '7.5',
                }, {
                    id: 3,
                    day: '2023-02-22',
                    day_format: 'Wed 2/22',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 1,
                    break_time_shifts: {
                        shift_1: '01:00',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '7',
                }, {
                    id: 4,
                    day: '2023-02-23',
                    day_format: 'Thu 2/23',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 0.5,
                    break_time_shifts: {
                        shift_1: '00:30',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '7.5',
                }, {
                    id: 5,
                    day: '2023-02-24',
                    day_format: 'Fri 2/24',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 1,
                    break_time_shifts: {
                        shift_1: '01:00',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '7',
                }, {
                    id: 6,
                    day: '2023-02-25',
                    day_format: 'Sat 2/25',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 0,
                    break_time_shifts: {
                        shift_1: '00:00',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '8',
                }, {
                    id: 7,
                    day: '2023-02-26',
                    day_format: 'Sun 2/26',
                    in_time: '9:30 AM',
                    out_time: '5:30 PM',
                    break_time: 0,
                    break_time_shifts: {
                        shift_1: '00:00',
                        shift_2: '00:00',
                        shift_3: '00:00'
                    },
                    total: '8',
                }, 
            ],
            approveHours: 0,
            payroll: true,
            payRate: 20,
            invoice: true,
            billRate: 30,

            name: '',
            email: '',
            role: 'supervisor',
            is_registered_from_demo: true,
            loading: false,
            errors: new Errors(),
            send_invoice : false
        }
    },

    created() {
        this.pageView()
    },

    computed: {
        totalHours() {
            return this.timesheet.filter(item => this.selectedDays.includes(item.id)).reduce((acc_2, item) => {
                return acc_2 + parseFloat(item.total);
            }, 0);
        },
        totalOTHours() {
            return this.totalHours > 40 ? parseFloat(this.totalHours) - parseInt(40) : 0;
        }
    },

    methods: {
        pageView() {
            setTimeout(function() {
                window.App.event(window.App.analytics, 'landing_page_v2', { clicked: 'true' });
            }, 5000);
        },

        selectDay(id) {
            let selectedDays = this.selectedDays;

            if(selectedDays.includes(id)){
                //selectedDays.splice(selectedDays.indexOf(id), 1);
            } else {
                selectedDays.push(id);
            }

            this.selectedDays = selectedDays;
        },

        submitTimesheet() {
            this.approveHours = this.totalHours;
        },

        approveTimesheet() {
            this.payroll = true;
        },

        submitPayroll() {
            this.invoice = true;
        },

        submitInvoice() {
            this.send_invoice = false;
            this.showDialogBox();
        },

        sendmeinvoice() {
            this.send_invoice = true;
            this.showDialogBox();
        },

        sendMeTimesheet() {
            this.send_invoice = false;
            this.showDialogBox();
        },

        showDialogBox() {
            window.App.event(window.App.analytics, 'experience_send_me_a_timesheet_click', { status: 'true' });
            this.$modal.show('try-for-free-modal', {send_invoice : this.send_invoice});
        },

        showConfirmationDialog(send_verification_email = false) {
            window.App.event(window.App.analytics, 'experience_connect_confirmed', { status: 'true' });
            let msg = "Please check your email to view and approve a sample timesheet.";
            if(send_verification_email){
                msg = "Please check your email"
            } else {
                if(this.send_invoice){
                    msg = "Please check your email we have sent you a sample invoice"
                }
            }
            this.$modal.show('general-message-modal', {
                title: 'Success',
                subtitle: '',
                message: msg
            });
        },

        submit() {
            window.App.event(window.App.analytics, 'experience_connect_click', { status: 'true' });
            this.loading = true;
            //let url = 'http://timesheet.local.com';
            let url = 'https://timesheet-stage.tempbutton.com';

            const email = this.email;

            const name = email.substring(0, email.indexOf('@'));
            
            this.name = name;

            console.log("sending invoice param", this.send_invoice);
            window.axios.post(url + '/api/auth/try_me', {
                name: this.name,
                email: this.email,
                is_registered_from_demo: this.is_registered_from_demo,
                role: this.role,
                send_invoice : this.send_invoice
            }).then(({data}) => {
                if(data.status == true) {
                    this.loading = false;
                    this.email = "";
                    this.showConfirmationDialog(data.data.send_verification_email);
                }
                this.cleanErrors();
            }).catch(error => {
                this.loading = false;
                this.errors.record(error.response.data.errors)
            });
        },

        cleanErrors() {
            this.errors = new Errors();
        }
        
    }
}
</script>