<template>
    <div>
        <a v-if="is_show" class="absolute right-0 top-0 block -mt-4 mr-2  text-grey hover:text-gray-600" href="javascript:void(0)" @click="$modal.hide(modelId)"><svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 fill-current" viewBox="0 0 512 512"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"/></svg></a>
        <div class="text-center pt-2">
            <div class="text-xl" :class="titleClass" v-html="title"></div>
            <div class="text-xl" v-if="secondTitle" :class="titleClass" v-html="secondTitle"></div>
            <div class="py-2 text-sm" v-if="subTitle" v-text="subTitle"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupHeader",
        props: ['title', 'modelId', 'secondTitle', 'subTitle', 'titleClass', 'showCloseIcon'],
        computed: {
            is_show() {
                if(this.showCloseIcon && this.showCloseIcon == 'false') {
                    return false;
                }

                return true;
            }
        }
    }
</script>

<style scoped>

</style>